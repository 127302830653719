.gastenboek-form input, .gastenboek-form textarea, .mobile-nav__close, body {
  font-family: Comic Sans MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px !important;
  width: 1px !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 700;
}

button {
  background: none;
  border: 0;
}

img {
  width: 100%;
  height: auto;
}

html, body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: green url("bg.b186c352.jpg") 0 0 / 204px 153px;
  font-size: 18px;
  line-height: 1.4;
  position: relative;
}

@media (min-width: 768px) {
  body {
    background-size: 340px 255px;
  }
}

body:before, body:after {
  content: "";
  z-index: 0;
  width: 80px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  body:before, body:after {
    width: 120px;
  }
}

body:before {
  background: url("gras-left.7ca03758.gif") 0 / contain repeat-y;
  left: 0;
}

body:after {
  background: url("gras-right.c0121634.gif") 100% / contain repeat-y;
  right: 0;
}

main {
  z-index: 1;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

@keyframes logoAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  display: flex;
  position: relative;
}

@media (max-width: 767px) {
  .header {
    flex-direction: row;
  }
}

.logo-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo-wrapper .giraf:nth-of-type(1) {
  transform: scale(-1, 1);
}

@media (max-width: 767px) {
  .logo-wrapper .giraf:nth-of-type(1) {
    z-index: -1;
    position: absolute;
    top: -20px;
    left: -20px;
  }
}

@media (max-width: 767px) {
  .logo-wrapper .giraf:nth-of-type(2) {
    display: none;
  }
}

.logo {
  width: 60%;
  max-width: 500px;
  min-width: 200px;
  flex-shrink: 0;
  margin: 0 20px;
}

.logo:hover > img {
  animation: logoAnimation 1s linear infinite;
}

.nav {
  margin-top: 20px;
  display: flex;
}

@media (max-width: 767px) {
  .nav {
    display: none;
  }
}

.nav ul {
  align-items: center;
  list-style: none;
  display: flex;
}

.nav__item {
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  display: inline-flex;
}

.nav__link {
  color: #fff;
  text-transform: uppercase;
  background-color: gray;
  border: 2px groove #fff;
  padding: 3px 30px;
  font-size: 16px;
}

@media (min-width: 768px) {
  .nav__link:hover, .nav__link:focus {
    color: #75f94c;
    border-color: #75f94c;
    box-shadow: 0 2px 8px #75f94c;
  }
}

.nav__link.active {
  background-color: #869f83;
}

.mobile-nav {
  z-index: 1000;
  background: green url("bg.b186c352.jpg") 0 0;
  padding: 30px;
  display: none;
  position: absolute;
  inset: 0;
}

.mobile-nav ul {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.mobile-nav ul > * + * {
  margin-top: 20px;
}

.mobile-nav__close {
  color: #fff;
  text-shadow: 2px 2px 10px #0009;
  font-size: 58px;
  position: absolute;
  top: 0;
  right: 20px;
}

.hamburger {
  cursor: pointer;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  padding: 10px;
  display: flex;
}

@media (min-width: 768px) {
  .hamburger {
    display: none;
  }
}

.hamburger > span {
  width: 30px;
  height: 3px;
  background-color: #000;
  border-radius: 5px;
  margin: 2px 0;
}

.grasmatten-container {
  padding: 30px 0;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.container--sm {
  max-width: 600px;
}

.container--md {
  max-width: 800px;
}

.beesten {
  width: 6vw;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 30px;
}

@media (max-width: 1039px) {
  .beesten {
    display: none;
  }
}

.beesten > * {
  width: 100%;
  display: block;
}

.beesten > * + * {
  margin-top: 2vw;
}

.beesten.links {
  left: 8vw;
  transform: scale(-1, 1);
}

.beesten.rechts {
  right: 8vw;
}

.giraf {
  width: 60px;
}

.eekhoorn {
  width: 50px;
}

.video {
  width: 320px;
  height: 180px;
  text-align: center;
  background-color: #8d8d8dcc;
  justify-content: center;
  align-items: center;
  margin: 15px auto 0;
  display: flex;
}

@media (min-width: 768px) {
  .video {
    width: 650px;
    height: 365px;
  }
}

.video__placeholder {
  text-transform: uppercase;
  color: #000000e6;
  font-size: 24px;
}

@media (min-width: 768px) {
  .video__placeholder {
    font-size: 32px;
  }
}

.video video {
  width: 100%;
  height: 100%;
}

.info {
  max-width: 800px;
  text-shadow: 0 0 1px #000;
  text-align: center;
  background-color: #0003;
  border: 5px solid #fff;
  margin: 20px auto 0;
  padding: 4px 20px 30px;
  font-size: 16px;
}

@media (min-width: 768px) {
  .info {
    padding: 4px 30px 40px;
    font-size: 18px;
  }
}

.info p + p {
  margin-top: 20px;
}

.info .smiley {
  width: 35px;
  vertical-align: middle;
  margin-left: 15px;
  display: inline;
}

@keyframes welcomeAnimation {
  0%, 100% {
    transform: translate(-150px, -10px);
  }

  5% {
    transform: translate(-120px, 10px);
  }

  10% {
    transform: translate(-90px, -10px);
  }

  15% {
    transform: translate(-60px, 10px);
  }

  20% {
    transform: translate(-30px, -10px);
  }

  25% {
    transform: translate(0, 10px);
  }

  30% {
    transform: translate(30px, -10px);
  }

  35% {
    transform: translate(60px, 10px);
  }

  40% {
    transform: translate(90px, -10px);
  }

  45% {
    transform: translate(120px, 10px);
  }

  50% {
    transform: translate(150px, -10px);
  }

  55% {
    transform: translate(120px, 10px);
  }

  60% {
    transform: translate(90px, -10px);
  }

  65% {
    transform: translate(60px, 10px);
  }

  70% {
    transform: translate(30px, -10px);
  }

  75% {
    transform: translate(0, 10px);
  }

  80% {
    transform: translate(-30px, -10px);
  }

  85% {
    transform: translate(-60px, 10px);
  }

  90% {
    transform: translate(-90px, -10px);
  }

  95% {
    transform: translate(-120px, 10px);
  }
}

.welcome-wrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  display: flex;
}

.welcome-wrapper:hover {
  transform: scale(1.6);
}

.welcome {
  width: 175px;
  animation: welcomeAnimation 3s linear infinite;
}

@media (min-width: 768px) {
  .welcome {
    width: 200px;
  }
}

.counter {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.counter__inner {
  background-color: #8c1b11;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: inline-flex;
  box-shadow: 5px 8px 12px #000c, inset 0 0 4px #00000080;
}

.counter__number {
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  margin: 0 1px;
  padding: 1px 2px;
  font-family: Impact, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 100%;
  box-shadow: inset 0 0 3px #0009;
}

@media (min-width: 768px) {
  .counter__number {
    margin: 0 2px;
    font-size: 26px;
  }
}

@keyframes popupAnimation {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.popup {
  z-index: 1000;
  align-items: flex-end;
  animation: popupAnimation 8s linear forwards;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 30px;
  transform: translateY(100%);
}

.popup__img {
  width: 175px;
}

@media (min-width: 768px) {
  .popup__img {
    width: 250px;
  }
}

.rik-wrapper {
  justify-content: center;
  padding: 20px;
  display: flex;
}

.rik {
  width: 100%;
  height: 300px;
  background: url("gold-borders-frame.3fa403b1.gif") center / contain no-repeat;
  position: relative;
}

@media (min-width: 768px) {
  .rik {
    width: 300px;
  }
}

.rik__rik {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  inset: 4px;
}

.rik__flower {
  width: 80px;
  height: 50px;
  object-fit: contain;
  position: absolute;
}

.rik__flower.flower-1 {
  top: -20px;
  left: calc(20% - 40px);
}

.rik__flower.flower-2 {
  top: -20px;
  left: calc(40% - 40px);
}

.rik__flower.flower-3 {
  top: -20px;
  left: calc(60% - 40px);
}

.rik__flower.flower-4 {
  top: -20px;
  left: calc(80% - 40px);
}

.rik__flower.flower-5 {
  bottom: -20px;
  left: calc(20% - 40px);
}

.rik__flower.flower-6 {
  bottom: -20px;
  left: calc(40% - 40px);
}

.rik__flower.flower-7 {
  bottom: -20px;
  left: calc(60% - 40px);
}

.rik__flower.flower-8 {
  bottom: -20px;
  left: calc(80% - 40px);
}

.rik__flower.flower-9 {
  top: calc(20% - 25px);
  left: -30px;
}

.rik__flower.flower-10 {
  top: calc(40% - 25px);
  left: -30px;
}

.rik__flower.flower-11 {
  top: calc(60% - 25px);
  left: -30px;
}

.rik__flower.flower-12 {
  top: calc(80% - 25px);
  left: -30px;
}

.rik__flower.flower-13 {
  top: calc(20% - 25px);
  right: -30px;
}

.rik__flower.flower-14 {
  top: calc(40% - 25px);
  right: -30px;
}

.rik__flower.flower-15 {
  top: calc(60% - 25px);
  right: -30px;
}

.rik__flower.flower-16 {
  top: calc(80% - 25px);
  right: -30px;
}

.collection {
  max-width: 1000px;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 30px auto 0;
  display: grid;
}

@media (min-width: 768px) {
  .collection {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin: 40px auto 0;
  }
}

.teaser {
  color: red;
  cursor: pointer;
  background-color: #8d8d8d;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  box-shadow: 0 0 10px 3px green;
}

@media (min-width: 768px) {
  .teaser {
    flex-direction: row;
  }
}

.teaser__media {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .teaser__media {
    flex: 0 0 250px;
  }
}

.teaser__media > img {
  height: 200px;
  object-fit: cover;
}

.teaser__info {
  font-size: 14px;
}

.teaser:hover, .teaser:focus {
  color: #ff0;
  box-shadow: 0 0 15px 3px #ff0;
}

.teaser:hover .teaser__media > img, .teaser:focus .teaser__media > img {
  border: 2px solid #ff0;
}

.gastenboek {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gastenboek__logo {
  max-width: 500px;
}

.gastenboek__logo:hover, .gastenboek__logo:focus {
  transform: scale(.85);
}

.gastenboek__entries {
  width: 100%;
  max-width: 500px;
  height: 250px;
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  margin-top: 30px;
  padding: 0 10px;
  overflow-y: scroll;
}

.gastenboek__entries ul {
  list-style: none;
}

.gastenboek__entry > span {
  color: #eb3223;
}

.gastenboek-form {
  max-width: 550px;
  text-align: center;
  margin: 30px auto 0;
}

.gastenboek-form label {
  display: flex;
}

.gastenboek-form label > span {
  color: #eb3223;
  text-transform: uppercase;
  text-align: center;
  flex: 0 0 120px;
  font-size: 17px;
  font-weight: 700;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .gastenboek-form label > span {
    flex: 0 0 170px;
    font-size: 18px;
  }
}

.gastenboek-form label + label {
  margin-top: 20px;
}

.gastenboek-form input, .gastenboek-form textarea {
  color: #fff;
  text-align: left;
  background-color: #183e0c;
  outline: 0;
  flex-grow: 1;
  font-size: 18px;
}

.gastenboek-form input[type="submit"] {
  width: 130px;
  height: 60px;
  vertical-align: middle;
  color: #eb3223;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  background: url("knop.6fe09033.png") center / contain no-repeat;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  line-height: 100%;
  display: inline-flex;
}

.gastenboek-form input[type="submit"]:hover, .gastenboek-form input[type="submit"]:focus {
  background-size: 110px 40px;
}

.gastenboek-form textarea {
  min-height: 140px;
}

@media (max-width: 767px) {
  .gastenboek-form + .counter {
    margin-bottom: 160px;
  }
}

/*# sourceMappingURL=index.0ca4b234.css.map */
