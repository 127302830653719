%font-base {
  font-family: "Comic Sans MS", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

%font-base--bold {
  font-family: "Comic Sans MS", Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}
