.collection {
  display: grid;
  grid-template-columns: 1fr;
  gap: space("m");

  max-width: $container-m;
  margin: space("l") auto 0;

  @include breakpoint("m") {
    grid-template-columns: 1fr 1fr;
    gap: space("l");

    margin: space("xl") auto 0;
  }
}

.teaser {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: $c-grey;
  color: red;
  box-shadow: 0 0 10px 3px green;

  cursor: pointer;

  @include breakpoint("m") {
    flex-direction: row;
  }

  &__media {
    width: 100%;

    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: underline;

    @include breakpoint("m") {
      flex: 250px 0 0;
    }

    > img {
      height: 200px;

      object-fit: cover;
    }
  }

  &__info {
    font-size: 14px;
  }

  &:hover,
  &:focus {
    color: yellow;
    box-shadow: 0 0 15px 3px yellow;

    .teaser__media > img {
      border: 2px solid yellow;
    }
  }
}
