@keyframes popupAnimation {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.popup {
  position: fixed;
  bottom: 0;
  right: space("l");
  z-index: 1000;

  display: flex;
  align-items: flex-end;

  transform: translateY(100%);

  animation: 8s popupAnimation linear forwards;

  &__img {
    width: 175px;

    @include breakpoint("m") {
      width: 250px;
    }
  }
}
