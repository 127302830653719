.nav {
  display: flex;

  margin-top: space("m");

  @include breakpoint("m", "max-width") {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;

    list-style: none;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin: 0 10px;
  }

  &__link {
    padding: 3px 30px;

    background-color: $c-dark-grey;
    border: 2px groove $c-white;

    font-size: 16px;
    color: $c-white;

    text-transform: uppercase;

    @include breakpoint("m") {
      &:hover,
      &:focus {
        border-color: $c-lime-green;
        box-shadow: 0 2px 8px $c-lime-green;

        color: $c-lime-green;
      }
    }

    &.active {
      background-color: $c-flets-green;
    }
  }
}

.mobile-nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;

  display: none;

  padding: space("l");

  background: green url("assets/img/bg.jpg") top left repeat;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: space("l");

    > * + * {
      margin-top: space("m");
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: space("m");

    @extend %font-base;
    font-size: 58px;
    color: $c-white;
    text-shadow: 2px 2px 10px rgba($c-black, .6);
  }
}

.hamburger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-left: space("s");
  padding: space("xs");

  background-color: $c-white;

  cursor: pointer;

  @include breakpoint("m") {
    display: none;
  }

  > span {
    width: 30px;
    height: 3px;

    margin: 2px 0;

    background-color: $c-black;
    border-radius: 5px;
  }
}
