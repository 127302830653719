.grasmatten-container {
  padding: space("l") 0;
}

.container {
  position: relative;

  width: 100%;

  margin: 0 auto;
  padding: 0 space("m");

  &--sm {
    max-width: $container-xs;
  }

  &--md {
    max-width: $container-s;
  }
}
