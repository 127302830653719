.beesten {
  position: fixed;
  top: space("l");

  display: flex;
  flex-direction: column;

  width: 6vw;

  @include breakpoint("l", "max-width") {
    display: none;
  }

  > * {
    display: block;

    width: 100%;

    + * {
      margin-top: 2vw;
    }
  }

  &.links {
    left: 8vw;

    transform: scale(-1, 1);
  }

  &.rechts {
    right: 8vw;
  }
}

.giraf {
  width: 60px;
}

.eekhoorn {
  width: 50px;
}
