html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

strong {
  font-weight: 700;
}

button {
  background: none;
  border: 0;
}

img {
  width: 100%;
  height: auto;
}
