.counter {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: space("l");

  &__inner {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 5px;

    background-color: $c-wine-red;
    border-radius: 5px;
    box-shadow: 5px 8px 12px rgba($c-black, 0.8),
      inset 0 0 4px rgba($c-black, 0.5);
  }

  &__number {
    margin: 0 1px;
    padding: 1px 2px;

    background-color: $c-white;
    border-radius: 3px;
    box-shadow: inset 0 0 3px rgba($c-black, 0.6);

    font-family: Impact, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 100%;
    color: $c-black;

    @include breakpoint("m") {
      margin: 0 2px;

      font-size: 26px;
    }
  }
}
