.rik-wrapper {
  display: flex;
  justify-content: center;

  padding: space("m");
}

.rik {
  position: relative;

  width: 100%;
  height: 300px;

  background: url("assets/img/gold-borders-frame.gif") center no-repeat;
  background-size: contain;

  @include breakpoint("m") {
    width: 300px;
  }

  &__rik {
    position: absolute;
    top: 4px;
    right: 4px;
    left: 4px;
    bottom: 4px;

    width: calc(100% - 8px);
    height: calc(100% - 8px);
  }

  $flower-width: 80px;
  $flower-height: 50px;

  &__flower {
    position: absolute;

    width: $flower-width;
    height: $flower-height;

    object-fit: contain;

    &.flower-1 {
      top: -20px;
      left: calc(20% - ($flower-width * .5));
    }

    &.flower-2 {
      top: -20px;
      left: calc(40% - ($flower-width * .5));
    }

    &.flower-3 {
      top: -20px;
      left: calc(60% - ($flower-width * .5));
    }

    &.flower-4 {
      top: -20px;
      left: calc(80% - ($flower-width * .5));
    }

    &.flower-5 {
      bottom: -20px;
      left: calc(20% - ($flower-width * .5));
    }

    &.flower-6 {
      bottom: -20px;
      left: calc(40% - ($flower-width * .5));
    }

    &.flower-7 {
      bottom: -20px;
      left: calc(60% - ($flower-width * .5));
    }

    &.flower-8 {
      bottom: -20px;
      left: calc(80% - ($flower-width * .5));
    }

    &.flower-9 {
      left: -30px;
      top: calc(20% - ($flower-height * .5));
    }

    &.flower-10 {
      left: -30px;
      top: calc(40% - ($flower-height * .5));
    }

    &.flower-11 {
      left: -30px;
      top: calc(60% - ($flower-height * .5));
    }

    &.flower-12 {
      left: -30px;
      top: calc(80% - ($flower-height * .5));
    }

    &.flower-13 {
      right: -30px;
      top: calc(20% - ($flower-height * .5));
    }

    &.flower-14 {
      right: -30px;
      top: calc(40% - ($flower-height * .5));
    }

    &.flower-15 {
      right: -30px;
      top: calc(60% - ($flower-height * .5));
    }

    &.flower-16 {
      right: -30px;
      top: calc(80% - ($flower-height * .5));
    }
  }
}
