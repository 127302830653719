.gastenboek {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__logo {
    max-width: 500px;

    &:hover,
    &:focus {
      transform: scale(.85);
    }
  }

  &__entries {
    width: 100%;
    max-width: 500px;
    height: 250px;

    margin-top: space("l");
    padding: 0 space("xs");

    background-color: $c-white;
    color: $c-black;

    user-select: none;
    overflow-y: scroll;

    ul {
      list-style: none;
    }
  }

  &__entry {
    > span {
      color: $c-red;
    }
  }
}

.gastenboek-form {
  max-width: 550px;
  margin: space("l") auto 0;

  text-align: center;

  label {
    display: flex;

    > span {
      flex: 120px 0 0;

      color: $c-red;
      font-size: 17px;
      text-transform: uppercase;
      text-decoration: underline;
      text-align: center;
      font-weight: 700;

      @include breakpoint("m") {
        flex: 170px 0 0;

        font-size: 18px;
      }
    }

    + label {
      margin-top: space("m");
    }
  }

  input,
  textarea {
    flex-grow: 1;

    outline: 0;

    @extend %font-base;
    font-size: 18px;
    background-color: $c-dark-green;
    color: $c-white;
    text-align: left;
  }

  input[type="submit"] {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 130px;
    height: 60px;

    margin-top: space("m");

    background: url("assets/img/knop.png") center no-repeat;
    background-size: contain;
    vertical-align: middle;

    color: $c-red;
    line-height: 100%;
    text-transform: uppercase;
    text-align: center;

    cursor: pointer;

    &:hover,
    &:focus {
      background-size: 110px 40px;
    }
  }

  textarea {
    min-height: 140px;
  }
}

.gastenboek-form + .counter {
  @include breakpoint("m", "max-width") {
    margin-bottom: 160px;
  }
}
