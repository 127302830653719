.video {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 320px;
  height: 180px;
  margin: space("s") auto 0;

  background-color: rgba($c-grey, 0.8);

  text-align: center;

  @include breakpoint("m") {
    width: 650px;
    height: 365px;
  }

  &__placeholder {
    font-size: 24px;
    text-transform: uppercase;

    color: rgba($c-black, 0.9);

    @include breakpoint("m") {
      font-size: 32px;
    }
  }

  video {
    width: 100%;
    height: 100%;
  }
}
