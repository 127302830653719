@use "sass:math";

html,
body {
  height: 100%;
}

html {
  font-size: math.div(100%, 1.6); // 1rem = 10px
}

body {
  position: relative;

  @extend %font-base;
  font-size: 18px;
  line-height: 1.4;

  background: green url("assets/img/bg.jpg") top left repeat;
  background-size: 204px 153px;
  color: $c-white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include breakpoint("m") {
    background-size: 340px 255px;
  }

  &::before,
  &::after {
    content: "";

    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 0;

    width: 80px;
    height: 100%;

    @include breakpoint("m") {
      width: 120px;
    }
  }

  &::before {
    left: 0;

    background: url("assets/img/gras-left.gif") left center repeat-y;
    background-size: contain;
  }

  &::after {
    right: 0;

    background: url("assets/img/gras-right.gif") right center repeat-y;
    background-size: contain;
  }
}

main {
  position: relative;
  z-index: 1;

  margin-top: space("m");

  overflow: hidden;
}
