.info {
  max-width: $container-s;

  margin: space("m") auto 0;
  padding: 4px space("m") space("l");

  background-color: rgba($c-black, .2);
  border: 5px solid $c-white;

  font-size: 16px;
  text-shadow: 0 0 1px $c-black;
  text-align: center;

  @include breakpoint("m") {
    padding: 4px space("l") space("xl");

    font-size: 18px;
  }

  p + p {
    margin-top: space("m");
  }

  .smiley {
    display: inline;

    width: 35px;

    margin-left: space("s");

    vertical-align: middle;
  }
}
