/* Primary colors */
$c-lime-green: #75f94c;
$c-red: #eb3223;
$c-wine-red: #8c1b11;
$c-flets-green: #869f83;
$c-dark-green: #183e0c;

/* Grayscales */
$c-black: #000000;
$c-white: #ffffff;
$c-light-grey: #ededed;
$c-grey: #8d8d8d;
$c-dark-grey: #808080;

/* Document colors */
$c-border: $c-light-grey;
