@keyframes welcomeAnimation {
  0%,
  100% {
    transform: translate(-150px, -10px);
  }

  5% {
    transform: translate(-120px, 10px);
  }

  10% {
    transform: translate(-90px, -10px);
  }

  15% {
    transform: translate(-60px, 10px);
  }

  20% {
    transform: translate(-30px, -10px);
  }

  25% {
    transform: translate(0, 10px);
  }

  30% {
    transform: translate(30px, -10px);
  }

  35% {
    transform: translate(60px, 10px);
  }

  40% {
    transform: translate(90px, -10px);
  }

  45% {
    transform: translate(120px, 10px);
  }

  50% {
    transform: translate(150px, -10px);
  }

  55% {
    transform: translate(120px, 10px);
  }

  60% {
    transform: translate(90px, -10px);
  }

  65% {
    transform: translate(60px, 10px);
  }

  70% {
    transform: translate(30px, -10px);
  }

  75% {
    transform: translate(0, 10px);
  }

  80% {
    transform: translate(-30px, -10px);
  }

  85% {
    transform: translate(-60px, 10px);
  }

  90% {
    transform: translate(-90px, -10px);
  }

  95% {
    transform: translate(-120px, 10px);
  }
}

.welcome-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: space("m") auto;

  cursor: pointer;

  &:hover {
    transform: scale(1.6);
  }
}

.welcome {
  width: 175px;

  animation: 3s welcomeAnimation linear infinite;

  @include breakpoint("m") {
    width: 200px;
  }
}
