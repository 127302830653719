@keyframes logoAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: space("m");

  @include breakpoint("m", "max-width") {
    flex-direction: row;
  }
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .giraf:nth-of-type(1) {
    transform: scale(-1, 1);

    @include breakpoint("m", "max-width") {
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: -1;
    }
  }

  .giraf:nth-of-type(2) {
    @include breakpoint("m", "max-width") {
      display: none;
    }
  }
}

.logo {
  flex-shrink: 0;

  width: 60%;
  max-width: 500px;
  min-width: 200px;

  margin: 0 space("m");

  &:hover {
    > img {
      animation: 1s logoAnimation linear infinite;
    }
  }
}
